import React from 'react'


const CustomerService: React.FC = () => (
  <div className="container px-8 max-w-screen-md mx-auto mt-32">
    <h1>고객 센터</h1>
    에잇코즈에서는 카카오톡 채널과 이메일을 통해서 고객님과 소통하고 있습니다.
    단, 고객 센터에서는 사주풀이 상담은 진행하지 않고 있으니 양해 바랍니다.
    <h3>카카오 채널</h3>
    <p>
      에잇코즈의 고객 상담 카카오톡 채널은 8-codes 입니다. 카카오톡에서 8-codes를 검색하시면 친구 추가 하실 수 있습니다.
    </p>
    <h3>이메일</h3>
    <p>
      에잇코즈의 고객 상담 이메일 주소는 support@8-codes.com 입니다.
    </p>

    <h3>환불 문의</h3>
    <p>
      사주 풀이 결과가 정상적으로 전달된 경우에는 전달 즉시 소모될 수 밖에 없는 디지털 컨텐츠의 속성상 환불이 불가능합니다.
      만일 결제가 완료되었는데도 에잇코즈의 서버, 혹은 고객님 단말의 문제로 인하여 사주 풀이 결과를 전달 받지 못하신 경우에만 100% 환불해 드립니다.
      단, 환불은 결제 완료 후 7일 이내에 신청해주셔야 합니다.
      카카오톡 채널이나 이메일을 통해서 환불 문의를 해주시면 됩니다.
      결제가 이루어진 날짜 및 대략적인 시간과 결제에 사용한 신용카드 번호 끝 4자리를 알려주시면 환불이 가능합니다.
    </p>

    <h3>전화 문의 불가</h3>
    <p>
      에잇코즈에서는 전화 상담을 진행하지 않고 있습니다.
      불편하시더라도 이 점 양해해주시기 바랍니다.
      카카오톡 채널과 이메일을 통해서 신속하고 정확한 상담이 가능합니다.
    </p>
  </div>
)


export default CustomerService